import agility from "@agility/content-fetch";
import config from "./config.json";

//initialize the api client
let api;
if (
  process.env.VUE_APP_BASE_URL.includes("localhost") ||
  process.env.VUE_APP_BASE_URL.includes("staging")
) {
  api = agility.getApi({
    guid: config.staging.guid,
    apiKey: config.staging.apiKey,
    isPreview: true,
  });
} else {
  api = agility.getApi({
    guid: config.live.guid,
    apiKey: config.live.apiKey,
  });
}

export default api;
